<template>
  <div id="dev-chat" :class="{ 'sidebar-open': isMenuOpen }" @click="handleOutsideClick">
    <div v-if="showTokenAlert" class="api-key-alert">
      OpenAI token is not set in settings. Please set it to use the chat functionality.
    </div>
    <!-- Menu toggle button -->
    <div class="menu-toggle" @click.stop="toggleMenu">☰</div>

    <!-- Sidebar menu -->
    <div class="sidebar" :class="{ 'open': isMenuOpen }" @click.stop>
      <button @click="editTags">Edit Tags</button>
      <button @click="editCorporateAI">Edit Corporate AI</button>
      <button @click="openSettings">Settings</button>
      <button @click="openFilesModal">Manage Files</button>
      <button @click="reset">Reset Chat</button>
      <button @click="toggleAudioStreaming" class="audio-stream">
        {{ audioManager.streaming ? 'Stop Audio' : 'Start Audio' }}
      </button>
      <button @click="toggleFilter">{{ showFilter ? 'Hide Filter' : 'Show Filter' }}</button>
    </div>

    <div class="filter-container" ref="filterContainer">
      <MessageFilter v-if="showFilter" @apply-simple-filter="applySimpleFilter" @apply-mongo-filter="applyMongoFilter"
        @generate-filter="handleGenerateFilter" />
    </div>

    <!-- Chat content stack -->
    <ChatContent v-for="(chatMessages, index) in filteredChatStack" :key="index" :messages="chatMessages"
      :isLoading="isLoading && index === filteredChatStack.length - 1"
      :showTriggerSystem2Button="showTriggerSystem2Button && index === 0" :depth="index"
      @triggerSystem2="triggerSystem2" @openTraceInfo="openTraceInfo" @close="closeTraceInfo(index)"
      @sendMessage="sendMessage" />

    <!-- Modals -->
    <TagEditorModal v-if="isTagEditorOpen" :processorService='chatService' @close="closeTagEditor" />
    <CorporateAIEditorModal v-if="isCorporateAIEditorOpen" :processorService='chatService'
      @close="closeCorporateAIEditor" />
    <SettingsModal v-if="isSettingsOpen" @close="closeSettings" @save="saveSettings" />
    <FilesModal v-if="isFilesModalOpen" :processorService='chatService' @close="closeFilesModal" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { ProcessorService } from './ProcessorService';
import { Preprocessor } from "./genericPreprocessor";
import { Message, Role, Settings } from './types';
import TagEditorModal from './TagEditorModal.vue';
import CorporateAIEditorModal from './CorporateAIEditorModal.vue';
import SettingsModal from './SettingsModal.vue';
import FilesModal from './FilesModal.vue';
import { useToast } from 'vue-toast-notification';
import { AudioManager } from './AudioManager';
import ChatContent from './ChatContent.vue';
import MessageFilter from './MessageFilter.vue';
import './DevChat.vue.css';
import { filterMessages } from "./jsonQuery"

export default defineComponent({
  components: {
    TagEditorModal,
    CorporateAIEditorModal,
    SettingsModal,
    FilesModal,
    ChatContent,
    MessageFilter,
  },
  props: {
    userName: { type: String, required: true },
    userEmail: { type: String, required: true },
    token: { type: String, required: true },
  },
  setup(props) {
    const $toast = useToast();
    const chatStack = ref<Message[][]>([]);
    const activeFilter = ref<string>('');
    const activeFilterType = ref<string>('simple');
    const showFilter = ref(false);
    const filterContainer = ref<HTMLElement | null>(null);
    const isMenuOpen = ref(false);
    const isLoading = ref(false);
    const isTagEditorOpen = ref(false);
    const isCorporateAIEditorOpen = ref(false);
    const isSettingsOpen = ref(false);
    const isFilesModalOpen = ref(false);
    const settings = ref<Settings>({
      openAiApiKey: '',
      chatModel: 'gpt-4o-mini',
      taggingModel: 'gpt-4o-mini'
    });
    const showTokenAlert = ref(true);
    const messageDict = ref<{ [key: number]: Message }>({});

    const chatService = new ProcessorService(props.token);
    const preprocessor = ref<Preprocessor | null>(null);
    const audioManager = ref(new AudioManager('', ''));

    const filteredChatStack = computed(() => {
      return chatStack.value.map(conversation => {
        return filterMessages(conversation, activeFilter.value, activeFilterType.value);
      });
    });

    const showTriggerSystem2Button = computed(() => {
      return !isLoading.value && chatStack.value[0]?.filter(m => m.role === Role.User).length > 0;
    });

    const handleOutsideClick = (event: MouseEvent) => {
      if (isMenuOpen.value && !(event.target as Element).closest('.sidebar')) {
        isMenuOpen.value = false;
      }
    };

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const toggleFilter = (event: Event) => {
      event.stopPropagation();
      showFilter.value = !showFilter.value;
    };

    const applySimpleFilter = async (filter: string) => {
      activeFilterType.value = "simple";
      activeFilter.value = filter;
    };

    const applyMongoFilter = async (filter: string) => {
      activeFilterType.value = "mongo";
      activeFilter.value = filter;
    };

    const handleGenerateFilter = (prompt: string, callback: (filter: string, filterType: string) => void) => {
      console.log('Generating filter for prompt:', prompt);

      chatService.handleNaturalLangQuery(prompt).then((response) => {
        if (response.problems) {
          console.error('Error generating filter:', response.problems);
          $toast.error(`Problems generating filter: ${response.problems}`, {
            position: 'top-right',
            duration: 3000
          });
          return;
        }

        if (response.query && response.query.length > 0) {
          activeFilterType.value = "mongo";
          activeFilter.value = response.query;

          console.log('Generated filter:', activeFilter.value);

          callback(activeFilter.value, "mongo");

          $toast.success('Filter generated successfully', {
            position: 'top-right',
            duration: 3000
          });
        }
      }).catch((error) => {
        console.error('Error generating filter:', error);
        $toast.error('Failed to generate filter', {
          position: 'top-right',
          duration: 3000
        });
      });
    };

    const fetchAllMessages = async () => {
      if (chatService) {
        try {
          const messages = await chatService.getAllMessages();
          chatStack.value[0] = messages;

          messageDict.value = messages.reduce((dict, message) => {
            if (message.id !== undefined) {
              dict[message.id] = message;
            } else {
              console.error('Message ID is missing:', message);
            }
            return dict;
          }, {} as { [key: number]: Message });

          console.log('Fetched all messages:', chatStack.value[0]);
          console.log('Constructed message dictionary:', messageDict.value);
        } catch (error) {
          $toast.error((error as Error).message || 'An error occurred while fetching messages', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        }
      }
    };

    const sendMessage = async (message: string, depth = 0) => {
      if (message.trim()) {
        const userMessage: Message = {
          id: -1,
          text: message,
          role: Role.User,
          timestamp: new Date()
        };
        chatStack.value[depth].push(userMessage);

        isLoading.value = true;

        try {
          await chatService.chat(message);
          await fetchAllMessages();
        } catch (error) {
          $toast.error((error as Error).message || 'An error occurred while processing your message', {
            position: 'top-right',
            dismissible: true,
            duration: 0
          });
        } finally {
          isLoading.value = false;
        }
      }
    };

    const editTags = () => {
      isMenuOpen.value = false;
      isTagEditorOpen.value = true;
    };

    const closeTagEditor = () => {
      isTagEditorOpen.value = false;
    };

    const editCorporateAI = () => {
      isMenuOpen.value = false;
      isCorporateAIEditorOpen.value = true;
    };

    const closeCorporateAIEditor = () => {
      isCorporateAIEditorOpen.value = false;
    };

    const reset = async () => {
      try {
        await chatService.resetChat();
        await fetchAllMessages();
      } catch (error) {
        console.error('Failed to reset chat:', error);
        $toast.error((error as Error).message || 'An error occurred while resetting the chat', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      }
    };

    const openSettings = () => {
      isMenuOpen.value = false;
      isSettingsOpen.value = true;
    };

    const closeSettings = () => {
      isSettingsOpen.value = false;
    };

    const saveSettings = (newSettings: Settings) => {
      localStorage.setItem('settings', JSON.stringify(newSettings));
      settings.value = newSettings;
      chatService.setSettings(settings.value);
      showTokenAlert.value = settings.value.openAiApiKey.length == 0;
      closeSettings();
    };

    const openFilesModal = () => {
      isMenuOpen.value = false;
      isFilesModalOpen.value = true;
    };

    const closeFilesModal = () => {
      isFilesModalOpen.value = false;
    };

    const openTraceInfo = (message: Message, depth: number) => {
      if (message.traceInfo && message.traceInfo.length) {
        chatStack.value.splice(depth + 1);
        const traceMessages = message.traceInfo.map(id => messageDict.value[id]).filter(msg => msg !== undefined);
        chatStack.value.push(traceMessages);
      }
    };

    const closeTraceInfo = (depth: number) => {
      if (depth > 0) {
        chatStack.value.splice(depth);
      }
    };

    const toggleAudioStreaming = () => {
      if (audioManager.value.streaming) {
        audioManager.value.stopStreaming();
      } else {
        audioManager.value.startStreaming();
      }
    };

    const triggerSystem2 = async () => {
      try {
        isLoading.value = true;
        await chatService.triggerSystem2();
        await fetchAllMessages();
      } catch (error) {
        $toast.error((error as Error).message || 'An error occurred while triggering System 2 thinking', {
          position: 'top-right',
          dismissible: true,
          duration: 0
        });
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      document.addEventListener('click', handleOutsideClick);
      await chatService.init();
      preprocessor.value = new Preprocessor(chatService);
      const savedSettings = localStorage.getItem('settings');
      if (savedSettings) {
        settings.value = JSON.parse(savedSettings);
      }
      chatService.setSettings(settings.value);
      showTokenAlert.value = settings.value.openAiApiKey.length == 0;
      audioManager.value = new AudioManager(chatService.apiUrl, chatService.token);
      await fetchAllMessages();
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleOutsideClick);
    });

    return {
      chatStack,
      activeFilter,
      activeFilterType,
      showFilter,
      filteredChatStack,
      filterContainer,
      isMenuOpen,
      isLoading,
      isTagEditorOpen,
      isCorporateAIEditorOpen,
      isSettingsOpen,
      isFilesModalOpen,
      settings,
      showTokenAlert,
      messageDict,
      chatService,
      preprocessor,
      audioManager,
      showTriggerSystem2Button,
      handleOutsideClick,
      toggleMenu,
      toggleFilter,
      applySimpleFilter,
      applyMongoFilter,
      handleGenerateFilter,
      sendMessage,
      editTags,
      closeTagEditor,
      editCorporateAI,
      closeCorporateAIEditor,
      reset,
      openSettings,
      closeSettings,
      saveSettings,
      openFilesModal,
      closeFilesModal,
      openTraceInfo,
      closeTraceInfo,
      toggleAudioStreaming,
      triggerSystem2,
    };
  },
});
</script>

<style scoped>
.filter-container {
  position: relative;
}
</style>