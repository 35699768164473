import { AIService } from "./AIService";
import { Message, Settings, TagSet, CorpSet } from "./types";
import { z } from 'zod';
//import { v4 as uuidv4 } from 'uuid';

export class ProcessorService implements AIService {
    public apiUrl: string;
    public token: string;
    private settings: Settings;

    constructor(token: string) {
        this.apiUrl = "not initialized";
        this.token = token;
        this.settings = { openAiApiKey: "", chatModel: "gpt-4o-mini", taggingModel: "gpt-4o-mini" };
    }

    async init() {
        await this.loadConfig();
    }

    private async loadConfig() {
        try {
            const response = await fetch('/devconfig.json'); // Adjust path as necessary
            const config = await response.json();
            if (config.developmentIP == "") {
                this.apiUrl = `/api`;
            } else {
                this.apiUrl = `http://${config.developmentIP}:3000/api`;
            }

            console.log('API URL set to:', this.apiUrl);
        } catch (error) {
            console.error('Failed to load config:', error);
        }
    }

    private async apiCall<T>(route: string, data: any = {}, method = 'POST'): Promise<T> {
        try {
            let url = `${this.apiUrl}/${route}`;
            const options: RequestInit = {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
            };

            if (method !== 'GET') {
                options.body = JSON.stringify({ ...data, token: this.token });
            } else {
                // For GET requests, append token as a query parameter
                url += (url.includes('?') ? '&' : '?') + `token=${encodeURIComponent(this.token)}`;
            }

            const response = await fetch(url, options);
            if (!response.ok) {
                let errorData = await response.json();
                while (errorData.error) {
                    errorData = errorData.error;
                }
                if (typeof errorData === 'string') {
                    errorData = { message: errorData };
                }
                throw new Error(errorData.message || 'An error occurred');
            }
            return await response.json();
        } catch (error) {
            console.error(`Error in ${route} API call:`, error);
            throw error;
        }
    }
    public setSettings(settings: Settings): void {
        this.settings = settings;
    }

    public startSpeechRecognition(): void {
        console.error("Speech api is not implemented.");
    }

    public stopSpeechRecognition(): void {
        console.error("Speech api is not implemented.");
    }

    public speakText(text: string): void {
        console.error("Speech api is not implemented.", text);
    }

    public stopSpeaking(): void {
        console.error("Speech api is not implemented.");
    }

    public async chat(prompt: string): Promise<string> {
        return this.generateResponse(prompt, "processor")
    }

    public async generateResponse(prompt: string, route = "generate"): Promise<string> {
        if (route === "generate") {
            route = "pre";
        }
        const data = await this.apiCall<{ message: string }>(route, { prompt: prompt, settings: this.settings });
        return data.message;
    }

    public async triggerSystem2(): Promise<string> {
        const data = await this.apiCall<{ message: string }>("system2", { settings: this.settings });
        return data.message;
    }

    public async getAllMessages(): Promise<Message[]> {
        return await this.apiCall<Message[]>("messages");
    }

    public async resetChat(): Promise<void> {
        await this.apiCall<{ message: string }>("reset");
    }

    public async getTags(): Promise<{ tagSet: TagSet[] }> {
        return await this.apiCall<{ tagSet: TagSet[] }>("get_tags");
    }

    public async setTags(tagSet: TagSet[]): Promise<void> {
        await this.apiCall<{ message: string }>("set_tags", { tagSet });
    }

    public async getDefaultTags(): Promise<{ tagSet: TagSet[] }> {
        return await this.apiCall<{ tagSet: TagSet[] }>("get_default_tags");
    }

    public async getCorporateAI(): Promise<{ corporateAI: CorpSet }> {
        return await this.apiCall<{ corporateAI: CorpSet }>("get_corporate_ai");
    }

    public async setCorporateAI(corporateAI: CorpSet): Promise<void> {
        await this.apiCall<{ message: string }>("set_corporate_ai", { corporateAI });
    }

    public async getDefaultCorporateAI(): Promise<{ corporateAI: CorpSet }> {
        return await this.apiCall<{ corporateAI: CorpSet }>("get_default_corporate_ai");
    }

    public async getUserFiles(): Promise<{ files: { id: string; name: string }[] }> {
        return await this.apiCall<{ files: { id: string; name: string }[] }>("user-files", {}, 'GET');
    }

    public async getFileContent(fileId: string): Promise<{ content: string }> {
        return await this.apiCall<{ content: string }>(`user-files/${fileId}/content`, {}, 'GET');
    }

    public async addUserFile(name: string): Promise<void> {
        await this.apiCall<{ message: string }>("user-files", { name }, 'POST');
    }

    public async removeUserFile(fileId: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}`, {}, 'DELETE');
    }

    public async renameUserFile(fileId: string, newName: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}/rename`, { name: newName }, 'PUT');
    }

    public async saveUserFileContent(fileId: string, content: string): Promise<void> {
        await this.apiCall<{ message: string }>(`user-files/${fileId}/content`, { content }, 'PUT');
    }

    public async generateNewCorp(prompt: string): Promise<CorpSet> {
        return await this.apiCall<CorpSet>("generate_new_corp", { settings: this.settings, prompt: prompt });
    }

    public async handleNaturalLangQuery(
        naturalLangQuery: string
    ): Promise<{ query: string, problems: string }> {
        try {
            const result = await this.apiCall<{ result: { query: string, problems: string } }>(
                'handle-natural-lang-query',
                {
                    settings: this.settings,
                    naturalLangQuery
                }
            );

            return result.result;
        } catch (error) {
            console.error('Error in ProcessorService.handleNaturalLangQuery:', error);
            throw new Error('Failed to generate structured output');
        }
    }
}