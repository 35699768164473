export enum Role {
  User = 'user',
  Bot = 'bot'
}
export interface Message {
  id?: number;
  text: string;
  role: string; // a restrictive set of values for role is an obstacle to supporting both gpt and claude
  from?: string;
  to?: string;
  timestamp?: Date;
  tags?: string[];
  isMemo?: boolean;
  traceInfo?: number[];
}

export interface TagSet {
  category: string;
  tags: string[];
}

export interface Settings {
  openAiApiKey: string;
  chatModel: string;
  taggingModel: string;
}

export type CorporateEmployeeType = 'CEO' | 'Manager' | 'Actor';


export interface CorpSet {
  ceo: CorpSetManager;
}

export interface CorpSetManager {
  id: string;
  type: CorporateEmployeeType;
  instructions: string[];
  managers?: CorpSetManager[];
  actors?: CorpSetActor[];
  visibility?: number;
}

export interface CorpSetActor {
  id: string;
  type: CorporateEmployeeType;
  instructions: string[];
}